<template>
	<div>
		<template v-if="data && Array.isArray(data) && data.length">
			<swiper
				:slides-per-view="slidesPerView"
				:space-between="spaceBetween"
				:loop="loop"
				:pagination="pagination"
				:navigation="navigation"
				:breakpoints="breakpoints"
				:autoplay="autoplay"
			>
				<swiper-slide v-for="(elem, idx) in data" :key="idx">
					<slot :item="elem"></slot>
				</swiper-slide>
			</swiper>
		</template>
		<template v-else>
			<div class="card">
				{{ $t("noData") }}
			</div>
		</template>
	</div>
</template>

<script>
import { Swiper, SwiperSlide, SwiperCore } from "swiper-vue2";
import { Navigation, Pagination, Autoplay, Thumbs } from "swiper";
import AppText from "@/components/shared-components/AppText.vue";

SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs]);

export default {
	props: {
		slidesPerView: {
			default: 4,
			type: Number,
		},
		spaceBetween: {
			default: 20,
			type: Number,
		},
		loop: {
			default: true,
			type: Boolean,
		},
		data: {
			default: [],
		},
		breakpoints: {
			type: Object,
		},
		autoplay: {
			default: () => ({
				delay: 4000,
				disableOnInteraction: false,
			}),
		},
	},
	components: {
		Swiper,
		SwiperSlide,
		AppText,
	},
	data() {
		return {
			pagination: {
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		};
	},
	methods: {
		getImageUrl(imageId) {
			return `https://picsum.photos/600/400/?image=${imageId}`;
		},
		nextSlide() {
			this.swiper.slideNext();

			// O'tqizishni tekshirish va boshqa amallarni bajarish
			if (this.swiper.isEnd) {
				// Agar o'tqizish oxiri bo'lsa, boshqa kerakli ishlarni bajarish
				// Masalan, yangi ma'lumotlarni yuklash
				this.loadMoreData();
			}
		},
	},
};
</script>

<style>
.swiper-pagination {
	margin-bottom: -5px !important;
}
</style>
<style lang="scss" scoped>
.card {
	cursor: grab;
}
.container {
	overflow: hidden;
}
.img-fluid {
	max-width: 100%;
	height: auto;
}
.w-100 {
	width: 100%;
}
.ml-auto,
.mx-auto {
	margin-left: auto;
}
.mr-auto,
.mx-auto {
	margin-right: auto;
}
</style>

